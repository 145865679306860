/* eslint-disable import/extensions, import/no-unresolved */
import * as getStatic from '@backpackjs/core-nextjs/get-static';

import IndexTemplate from '../templates/home';
import { PageErrorBoundary, PageHead } from '../_components';

export const getStaticProps = getStatic.props.homepage;

export default function Index({ isPreview, renderSections, ...props }) {
  const homePage = props?.page;

  return (
    <PageErrorBoundary>
      <PageHead page={homePage}>
        <IndexTemplate {...props} renderSections={renderSections} />
      </PageHead>
    </PageErrorBoundary>
  );
}
